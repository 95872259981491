import axios from 'axios';

const NOT_ACCEPTABLE = 406;
const UNPROCESSABLE_ENTITY = 422;
const UNAUTHORIZED = 401;

const requestHistory = [];

const handleRequest = (request) => {
  requestHistory.push(request);
  if (requestHistory.length > 4) {
    requestHistory.shift();
  }
};

const network = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
  withCredentials: true,
  timeout: 180000,
  xsrfCookieName: 'CSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-Token',
  headers: {
    'Accept': 'application/json',
  },
  validateStatus(status) {
    if (status === NOT_ACCEPTABLE || status === UNAUTHORIZED) {
      window.location.assign(process.env.REACT_APP_API_PATH);
      return status;
    }
    if (status === UNPROCESSABLE_ENTITY) {
      return false; 
    }

    return status >= 200 && status < 500; // default
  },
});

// response interceptor
network.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      window.location.assign(process.env.REACT_APP_API_PATH);
    }
    return Promise.reject(error);
  }
);

network.interceptors.request.use(
  (config) => {
    handleRequest({
      url: config.url,
      method: config.method
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default network;
export { network, requestHistory };
