import { useState } from 'react';
import {
  Block,
  BlockMargin,
  FilledButton,
  TextArea,
  TextAccentSmall,
  SecondaryBtnHigh,
  TitleBlack,
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Close } from 'assets/images/close.svg';
import network from 'base/network';

const Buttons = styled.div`
  display: flex;
  padding: 20px 0;
  margin:0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  button {
  white-space: nowrap;
  }
`;

const Box = styled(Block)`
  margin: 0 auto;
  padding: 35px;
  max-width: 860px;
  font-size: 14px;
  position: relative;

  .close {
    position:absolute;
    cursor: pointer;
    right: 20px;
    top: 20px;
  }
  form {
    min-width: 50%;
    padding: 10px 0 0;
    textarea {
      min-height: 70px;
      margin: 5px 10px;
    }
  }
  p {
    font-size: 14px
    margin: 15px 10px 5px;
  } 
  @media (max-width: 1068px) {
    flex-direction: column;
    width: 90%;
    height: 80vh;
    overflow-y: scroll;
  }
`;

const Content = styled.div`
  padding: 5px 30px 10px;
  text-align: center;
  margin: 0 auto;
  img {
    margin: 40px 0 20px
  }
  h3 {
    font-size: 17px;
  }
  .offer {
    max-width: 240px;
    margin: 10px auto 0;
  }
  
`;

const Success = styled(Box)`
  background: url('https://assets.visitorqueue.com/images/banners/Reason.svg') no-repeat center/ 106%;
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 30px
  h1 {
    padding-top: 120px;
    text-align: center;
    max-width: 280px;
    p {
    font-weight: 400;}
  }
  @media (max-width:1068px) {
   background: url('https://assets.visitorqueue.com/images/banners/Reason.svg') no-repeat center/ cover;
   height: 72vh;
  }
`;

export default function SurveyDeactivate({ email,
  handleSubscribeOff, handleDeactivateAcc, setDiscount}) {
  const { t: homeT } = useTranslation('home');
  const [message, setMessage] = useState('');
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [sending, setSending] = useState(false);

  function handleAnswer1(e) {
    setAnswer1(e.target.value);
  }

  function handleAnswer2(e) {
    setAnswer2(e.target.value);
  }

  function handleAnswer3(e) {
    setAnswer3(e.target.value);
  }

  function handleSubmitSurvey(e){
    e.preventDefault();
    if (answer1 == '' || answer2 == '' || answer3 == '') {
      setMessage('Please, answer the question');
      return;
    } else {
      setSending(true);
      const formData = {
        "Why did you choose to switch to a different lead generation product? What did they have that we didn't?": 
        answer1,
        "Which product did you choose?": answer2,
        "Are you moving to a paid plan with this other product?": answer3
      };
      network.post(`/api/surveys`, {
        email: email,
        answers: formData,
        event: "deactivate_survey_switch_to_another_product_amazon_gift_card"
      })
        .then(data => {
          setSending(false);
          setIsSent(true);
          // setTimeout(() => {
          //   handleDeactivate();
          // }, 3000);
          return data;
        });
    }
  }

  function handleSubmit(e){
    e.preventDefault();
    handleDeactivateAcc();
  }

  return (
    <>
      {isSent ? (
        <Success>
          <TitleBlack>{homeT('thankFeedback')}
            <p>{homeT('sendGift')}</p>
          </TitleBlack>
          <SecondaryBtnHigh onClick={setDiscount}>
            {homeT('close')}
          </SecondaryBtnHigh>
        </Success>
      ) :
        <Box>
          <Close className='close' onClick={handleSubscribeOff}/>
          <Content>
            <h3>{homeT('youFeedback')}</h3>
            <img src="https://assets.visitorqueue.com/images/banners/amazon_card.png" />
            <p className='offer'>{homeT('weOffer')}</p>
          </Content>

          <form>
            <p>{homeT('deactivate1')}</p>
            <TextArea 
              type="text"
              value={answer1}
              onChange={handleAnswer1}
              required={true}/>
            <p>{homeT('deactivate2')}</p>
            <TextArea 
              type="text"
              value={answer2}
              onChange={handleAnswer2}
              required={true}/>
            <p>{homeT('deactivate3')}</p>
            <TextArea 
              type="text"
              value={answer3}
              onChange={handleAnswer3}
              required={true}/>
            <TextAccentSmall>{message}</TextAccentSmall>
            <Buttons>
              <BlockMargin>
                <SecondaryBtnHigh onClick={handleSubmit}>
                  {homeT('skipDeactivate')}
                </SecondaryBtnHigh>
              </BlockMargin>
              <BlockMargin>
                <FilledButton onClick={handleSubmitSurvey}>
                  {sending ? 'sending...' : homeT('submitDeactivate')}
                </FilledButton>
              </BlockMargin>
            </Buttons>
          </form>
        </Box>}
    </>
  );
}

SurveyDeactivate.propTypes = {
  handleSubscribeOff: func,
  email: string,
  handleDeactivateAcc: func,
  setDiscount: func
};
  