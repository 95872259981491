import { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { isStatusSucceed } from 'base/utils';
import { fetchDashboardQueues } from 'store/slices/dashboardQueues';
import { fetchGAViewIds, setSelectedId } from 'store/slices/gaViewId';
import makeAsOptionsSet from 'base/utils/makeAsOptionsSet';
import { fetchUserEdit } from 'store/slices/user';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  FlexWrapper,
  Header,
  StyledSelect,
  Modal,
  AccentButtonGradient,
  OverflowSolid
} from 'base/styled';
import { components } from 'react-select';
import AddWebsite from './Leads/Header/AddWebsite';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as LogoMobile } from 'assets/images/logo-mobile.svg';
import { ReactComponent as Plus } from 'assets/images/plus_white.svg';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { ReactComponent as FaSun } from 'assets/images/sunsetted.svg';
import Responsive from 'context/responsive';
import Burger from './Burger/Burger';
import HeaderNav from './Leads/Header/HeaderNav';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Impersonate from './Impersonate';
import Topbar from './Topbar';
import { any, bool, func } from 'prop-types';
import { Helmet } from 'react-helmet-async';
import Reactivate from './Reactivate';
import network from 'base/network';

const Flex = styled(FlexWrapper)`
@media (min-width: 1375px) {
  .css-1fpipmq-Control {
    width: 300px;
  } 
}
  @media (max-width: 768px) {
    justify-content: center;
  }
  
  justify-content: flex-start;
`;

const Button = styled(AccentButtonGradient)`
  svg {
    margin-right: 8px;
  }
`;

const BurgerBox = styled.div`
  position: relative;
`;

const BurgerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  cursor: pointer;
  span {
    background: #2c2c2c;
    border-radius: 2px;
    height: 2px;
    margin: 4px;
    text-align: right;
    &:nth-child(1) {
      width: 38px;
    }
    &:nth-child(2) {
      width: 22px;
    }
    &:nth-child(3) {
      width: 28px;
    }
  }
`;

const Select = styled(StyledSelect)`
  padding-left: 15px;
  .css-1uccc91-singleValue,
  .css-1wa3eu0-placeholder {
    font-size: 14px;
    font-weight: 400;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  @media (max-width: 1300px) {
    .css-aqbxid-Control, 
    .css-fs5wjo, 
    .css-88aio2-Control {
      width: auto
    }
  }
`;

const FlexWrap = styled(Flex)`
  align-items: center
`;

export default function HeadContent({impersonate, setHidden}) {
  const { t: homeT } = useTranslation('home');
  const dispatch = useDispatch();
  const [openAdd, setOpenAdd] = useState();
  const [openUser, setOpenUser] = useState();
  const [openNotification, setOpenNotification] = useState();
  const [notification] = useState(false);
  const [sentArg, setSentArg] = useState(false);
  const [cookies, setCookie] = useCookies(['name']);
  const [user_id, setUserID] = useState();
  const ctx = useContext(Responsive);

  function handleOpenAdd() {
    setOpenAdd(true);
    setHidden(true);
  }
 
  function handleOpenAddOff() {
    setOpenAdd();
    setHidden();
  }

  function handleOpenNotification() {
    setOpenNotification(true);
  }

  function handleOpenNotificationOff() {
    setOpenNotification();
  }

  function handleOpenUser() {
    setOpenUser(true);
  }

  function handleOpenUserOff() {
    setOpenUser();
  }

  function handleSelectChange(payload) {
    dispatch(setSelectedId(payload));
    window.localStorage.setItem('SELECTED', JSON.stringify(payload.value));
  }

  const { user, status, errors } = useSelector((state) => state.userReducer);
  
  const { selectedId, ids } = useSelector((state) => state.gaViewIdReducer);
  
  const { site } = useSelector(
    (state) => state.websitesReducer
  );

  const { details } = useSelector(
    (state) => state.accountReducer
  );
  
  useEffect(() => {
    ctx.setIsTrial(details?.trial_end_date);
    ctx.setActive(details?.active);
    if(details?.should_send_ts_added && !sentArg) {
      dataLayer.push({
        'event': 'eventTracking',
        'eventAction': 'add tracking script',
      });
      setSentArg(true);
      network
        .post('/api/account/ts_added_sent')
        .then(({ data }) => {
          return data;
        })
        .catch((error) => console.log(error));
    }
    
    /* eslint-disable */
  }, [details]);
    /* eslint-enable */

  useEffect(() => {
    const url = process.env.REACT_APP_API_PATH;
    if(errors == '401 Unauthorized' ) {
      window.open(url, '_self');
    } 
  }, [dispatch, status, errors]);

  useEffect(() => {
    const url = process.env.REACT_APP_API_PATH + `/interface/classic`;
    if(user && user?.settings && !user?.settings?.use_new_interface){
      window.open(url, '_self');
    }
    if (!user) {
      dispatch(fetchUserEdit());
    }
    ctx.setRole(user?.role);

    if(user && user?.impersonated == true) {
      window.localStorage.setItem('IMPERSONATE', true);
      window.localStorage.setItem('USER', JSON.stringify(user));
    }
    
    /* eslint-disable */
  }, [dispatch, user]);
   /* eslint-enable */
  
  useEffect(() => {
    if(user && !user?.impersonated && user?.id) {     
     
      if(!cookies.ID || cookies.ID != user?.id) {
        setCookie('ID', user.id, {
          path: '/',
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30), // 30 days
          sameSite: 'Lax',
          secure: true, 
        });
        console.log('set cookies id');
      } else if(cookies.ID) {
        window.Attribution.identify(user?.id, {
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          createdAt: user.created_at,
          companyId: user.account?.id,
          companyName: user.account?.name
        });
      }
      else return;
    }
  }, [dispatch, user, setCookie, cookies]);
 
  useEffect(() => {
    if(user && user?.impersonated == true) {
      window.location.reload();
    }
    return () => {
      window.location.reload();
    };
    /* eslint-disable */
  }, []);
    /* eslint-enable */
  
  useEffect(() => {
    const data = ctx.impersonate ? 
      null : 
      ids?.find(el => el.id == window.localStorage.getItem('SELECTED'));

    if (ids?.length && !data ) {
      dispatch(setSelectedId({ value: ids[0]?.id, label: ids[0]?.name }));
    }
    if (ids?.length && data ) {
      dispatch(setSelectedId({ value: data?.id, label: data?.name }));
    } 
    /* eslint-disable */
  }, [dispatch, ids, ctx.isImpersonate]);
    /* eslint-enable */
  const [fetchCount, setFetchCount] = useState(0);
  useEffect(() => {
    if (!ids?.length) {
      if(fetchCount < 1) {
        dispatch(fetchGAViewIds());
        setFetchCount(prevCount => prevCount + 1);
      } 
    }
    /* eslint-disable */
  }, [dispatch, ids, fetchCount]);
      /* eslint-enable */

  useEffect(() => {
    if (selectedId) {
      dispatch(fetchDashboardQueues( {id: selectedId?.value, per_page: 100, page: 1}));
    }
  }, [dispatch, selectedId, ctx.isImpersonate, impersonate]);

  const options = makeAsOptionsSet(ids, 'id', 'name', 'sunsetted');
  const notificationArr = [
    {
      title: 'Serhii Lukovenkov',
      value: 'assigned you new lead',
    },
    {
      title: 'Serhii Nadolinskyi',
      value: 'assigned you new lead',
    },
  ];

  function handleNavbar() {
    ctx.setNavbar(true);
  }

  function handleNavbarOff() {
    ctx.setNavbar(false);
  }

  let location = useLocation();
  const showAddBtn = location.pathname === '/dashboard' || location.pathname === '/' ;
  const home = location.pathname === '/dashboard' || location.pathname === '/' || location.pathname === '/benchmark' ;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#000000',
      fontSize: '12px',
      background: 'inherit',
      borderBottom: state.isSelected ? '1px solid #EDEDED' : '0',
      '&:hover': {
        background: `#FFF9F9`,
      },
      padding: state.isSelected ? '10px 5px 10px 11px' : '10px 5px 10px 35px',
      width: ctx.isMobile ? 200 : 500,
    }),
    control: () => ({
      display: 'flex',
      borderRadius: '20px',
      //width: ctx.isMobile ? 180 : 300,
      paddingLeft: '50px',
      border: 'none',
      background: `#fff`,
      fontWeight: '400',
      color: 'red',
      boxShadow: 'none',
      '@media only screen and (min-width: 1300px)': {
        width: 300,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      width: ctx.isMobile ? 240 : 400,
      color: state.selectProps.menuColor,
      padding: 0,
      overflowX: 'hidden',
      display: 'inline-block',
      '@media only screen and (min-width: 1200px)': {
        width: 400,
      },
    }),
  };

  const IconOption = (props) => {
    const { data, isSelected } = props;
    return (
      <components.Option {...props}>
        {isSelected && <CheckIcon className='check' />}
        {data.label}
        {data.sunset}
        {data.sunset && <FaSun style={{ marginLeft: '15px', marginBottom: '-7px' }} />}
      </components.Option>
    );
  };
  
  useEffect(() => {
    if (!user_id) {
      setUserID(details?.stripe_customer_id);
    }
    /* eslint-disable */
  }, [details]);
    /* eslint-enable */

  useEffect(() => {
  
    if (!user_id) {
      return;
    }
  
    if (!document.getElementById('profitwell-js')) {
  
      const script = document.createElement('script');
      script.id = 'profitwell-js';
      script.setAttribute('data-pw-auth', 'ee41ad7eb7b99af410a20842d634db84');
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){
          i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
          a=s.createElement(g);
          m=s.getElementsByTagName(g)[0];
          a.async=1;
          a.src=r+'?auth='+s.getElementById(o+'-js').getAttribute('data-pw-auth');
          m.parentNode.insertBefore(a,m);
        })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
  
        profitwell('start', { user_id: '${user_id}' });
      `;
  
      document.head.appendChild(script);
    } else {
      if (window.profitwell) {
        window.profitwell('start', { user_id });
      }
    }
  }, [user_id]);
  
  return (
    <>
      {user && <Helmet>   
        <script async src="https://staging.visitorqueue.com/consent_banner.js"></script>
      </Helmet>}
      {ctx.isImpersonate && <Impersonate impersonate={impersonate} />}
      {ctx.isTrial && <Topbar impersonate={impersonate} />}
      {ctx.isLiteFree && <Topbar />}
      {ctx.sunset && <Topbar />}
      {details?.active == false && <Reactivate />}
      {ctx.hitLimit && <Topbar />}
      <Header className={ctx.isImpersonate || ctx.isLiteFree || ctx.isTrial ||
         ctx.hitLimit || ctx.sunset ? 'impersonate' : ''}>
        <Container fluid>
          <Row>
            <Col xs="2" md="2" lg="4" className="center">
              <a href="/">{ctx.isMobile ? <LogoMobile /> : <Logo />}</a>
            </Col>
            <Col xs="10" md="10" lg="8">
              <Row>
                <Col xs="9" md="7" lg="8">
                  <FlexWrap>
                    {home && (
                      <Select
                        value={selectedId}
                        options={options}
                        /* eslint-disable */
                        onChange={handleSelectChange}
                        styles={customStyles}
                        components={{ IndicatorSeparator: () => null, Option: IconOption,}}
                      />
                    )}
                    {showAddBtn && !ctx.isMobile && (
                      <Button onClick={handleOpenAdd}>
                        <Plus />
                        {homeT('add')}
                      </Button>
                    )}
                    {openAdd && (
                      <>
                        <Modal>
                          <OverflowSolid onClick={handleOpenAddOff}></OverflowSolid>
                          <>
                            <AddWebsite
                              handleOpenAddOff={handleOpenAddOff}
                              site={site}
                              header={true}
                            />
                          </>
                        </Modal>
                      </>
                    )}
                  </FlexWrap>
                </Col>
                <Col xs="3" md="5" lg="4" offset="2">
                  {!ctx.isMobile && isStatusSucceed(status) && (
                    <HeaderNav
                      handleNavbarOff={handleNavbarOff}
                      handleOpenNotification={handleOpenNotification}
                      notification={notification}
                      openNotification={openNotification}
                      handleOpenNotificationOff={handleOpenNotificationOff}
                      notificationArr={notificationArr}
                      handleOpenUser={handleOpenUser}
                      handleOpenUserOff={handleOpenUserOff}
                      openUser={openUser}
                      user={user}
                    />
                  )}
                  {ctx.isMobile && (
                    <BurgerBox>
                      {(ctx.navbar && !ctx.details) ? (
                        <Burger
                          handleNavbarOff={handleNavbarOff}
                          handleOpenNotification={handleOpenNotification}
                          notification={notification}
                          openNotification={openNotification}
                          handleOpenNotificationOff={handleOpenNotificationOff}
                          notificationArr={notificationArr}
                          handleOpenUser={handleOpenUser}
                          handleOpenUserOff={handleOpenUserOff}
                          openUser={openUser}
                          user={user}
                        />
                        
                      ) : (
                        <BurgerIcon
                          onClick={handleNavbar}
                          /* eslint-enable */
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                        </BurgerIcon>
                      )}
                    </BurgerBox>
                   
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Header>
    </>
  );
}

HeadContent.propTypes = {
  impersonate: any,
  setHidden: func,
  data: any,
  isSelected: bool
};