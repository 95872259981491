import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { any, array, bool, func, object, string } from 'prop-types';
import { makeAsOptions } from 'base/utils';
import { setSelectedId } from 'store/slices/gaViewId';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import {
  Block,
  Check,
  FilledButton,
  Flex,
  FlexWrapperStart,
  FlexWrapperStartMiddle,
  SecondaryBtnHigh,
  StyledSelect,
  StyledSelectSmall,
  TextAccentExtraSmall,
  TextAccentThin,
  TextBlack,
  TextBlackThin,
  TextField,
} from 'base/styled';
import { ReactComponent as Plus } from 'assets/images/plus.svg';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as Checked } from 'assets/images/check_sm.svg';
import Cascad from './../../base/components/Leads/Filters/Cascad';
import { postPersonalization, putPersonalization } from 'store/slices/personalizations';

const Form = styled.form`
  width: 80%;
  margin-bottom: 30px;
  .gNivYh {
    align-items: center;
  }
  .dBqIOv {
    position: absolute;
    top: -25px;
  }
  .buYAXv {
    top: 31px;
  }
  .cSqraV {
    margin: 15px 0;
  }
  .jrbpue {
    height: auto;
  }
  .define {
    margin: 35px 0 -12px;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
  .cascad:nth-child(8) .operator,
  .cascad:nth-child(8) .delete {
    display: none;
  }
  .cascad {
    .select-wrap {
      width: 100%;
    }
   .cascad-select {
    min-width: auto;
    width: 100%
   }
  }

`;

const Wrapper = styled(FlexWrapperStartMiddle)`
  height: 55px;
  @media (max-width: 768px) {
    height: 90px;
    .small {
      width: 60px;
      margin-bottom: 10px;
    }
  }
`;

const Button = styled.div`
  position: absolute;
  right: -25px;
  bottom: 2px;
  cursor: pointer;
  width: 30px;
  height: 28px;
  @media (max-width: 768px) {
    right: -45px;
  }
`;

const AddButton = styled.div`
  display: flex;
  padding-top: 30px;
  cursor: pointer;
  svg {
    margin: 7px 10px 0 0;
  }
`;

const CascadWrapper = styled.div`
  position: relative;
  &#destroy {
    display: none;
  }
  &.personalize {
   .select-wrap {
      max-width: 100%;
      margin-right: 6px;
   }
   .delete {
    right: -39px
   }
   .iluTDR {
      justify-content: space-between;
      gap: 10px;
      @media (min-width: 1300px) {
        width: 102%
      }
     
   }
  }
`;

const InsertButton = styled(AddButton)`
 padding-top: 10px;
`;

const Select = styled(StyledSelect)`
  margin-right: 0;
`;

const Label = styled(TextBlack)`
  margin-top: 35px;
`;

const RedirectBox = styled.div`
  padding: 25px 25px 25px 50px;
  margin-top: 40px
  background: #FFFFFF;
  border: 1px solid #C6D5DD;
  border-radius: 9px;
  position: relative;
  .description {
    max-width: 70%;
    line-height: 1.3em;
    margin: 0;
  }
  @media (max-width: 768px) {
    padding: 25px 38px 25px 31px;
  }
`;

const CheckBox = styled(Check)`
  left: 0;
  height: 50px;
  & + span {
    left: -15px;
    top: 4px
  }
`;

const FieldsWrapper = styled.div`
  display: flex;
  position: relative;

`;

const RedirectCascad = styled.div`
  margin-top: 30px;
  &:nth-child(2) {
    .delete {
      display: none
    }
  }
`;

const Fields = styled.div`
  margin: 0 5px;
  width: 45%;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end
  button {
    margin-left: 15px;
  }
`;

const Notification = styled(Block)`
  position: fixed;
  bottom: 20px;
  p {
    line-height: 1.4em;
    margin: 0;
    max-width: 770px;
    padding: 0 20px
  }
`;

const Close = styled.div`
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9
  })
};

function generateEmptyCriteria() {
  return {
    key: '',
    operator: '',
    value: '',
    join_operator: 'AND',
    multiple_filter: {
      path_value: '',
      time_value: '',
      count_value: '',
      path_operator: '',
      time_operator: '',
      count_operator: '',
      value_to: '',
      value_from: ''
    },
    _id: uuidv4()
  };
}

function generateEmptyRedirectFields() {
  return {
    from: '',
    to: '',
    _id: uuidv4()
  };
}

export default function NewForm({ handleShowFormOff, edit, editItem, statusNew, notification, handleOffNotification,
  gic, setEditted, id,
  naics}) {
  const { t: rootT } = useTranslation('');
  const { t: personalizationT } = useTranslation('personalization');
  const [name, setName] = useState(edit ? editItem.name : '');
  const [url, setUrl] = useState(edit ? editItem.page_path : '');
  const [filterValue, setFilterValue] = useState(edit ? editItem.lead_filters_attributes[0].value : '');
  const [filterType, setFilterType] = useState(edit ? editItem.lead_filters_attributes[0].key : '');
  const [filterEqual, setFilterEqual] = useState(edit ? editItem.lead_filters_attributes[0].operator : 'eq');  
  const { selectedId, ids } = useSelector((state) => state.gaViewIdReducer);
  const filterNameOptions = makeAsOptions(ids, 'id', 'name');
  const [error, setError] = useState(false);
  const [high, setHigh] = useState('');
  const [criteriaErrors, setCriteriaErrors] = useState({});
  const [isSelectValue, setIsSelectValue] = useState(false);
  const [criteria, setCriteria] = useState(
    edit ? editItem?.lead_filters_attributes : [generateEmptyCriteria()]
  );
  const [redirectFields, setRedirectFields] = useState(
    edit ? editItem?.redirects : [generateEmptyRedirectFields()]
  );
  const [redirect, setRedirect] = useState(edit ? editItem.redirects.length > 0 : false);
  const dispatch = useDispatch();

  const operatorOptions = [
    { value: 'OR', label: 'or' },
    { value: 'AND', label: 'and' },
  ];

  function handleSubmit(formFields) {
    dispatch(postPersonalization(formFields)); 
  }

  function handleName(e) {
    setName(e.target.value);
  }

  function handleUrl(e) {
    setUrl(e.target.value);
  }

  function handleSelectChange(payload) {
    dispatch(setSelectedId(payload));
  }

  function handleRedirect() {
    setRedirect(!redirect);
  }

  function filterValueHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    if(isSelectValue) {
      newCriteria[currentItemIndex] = {
        ...newCriteria[currentItemIndex],
        value: (Array.isArray(e) ? e.map(x => x.value) : []).toString()
      };
      setCriteria(newCriteria);
      setFilterValue((Array.isArray(e) ? e.map(x => x.value) : []).toString());
    } else {
      newCriteria[currentItemIndex] = {
        ...newCriteria[currentItemIndex],
        value: e.target.value
      };
      setCriteria(newCriteria);
      setFilterValue(e.target.value);
    }
  }

  function filterValueNaicsHandler(arr, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      value: arr
    };
    setCriteria(newCriteria);
    setFilterValue(arr);
  }

  function filterTypeHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      key: e.value
    };
    setCriteria(newCriteria);
    setFilterType(e.value);
  }

  function filterEqualHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: e.value
    };
    setCriteria(newCriteria);
    setFilterEqual(e.value);
    console.log(filterEqual);
  }

  function filterOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      join_operator: e.value 
    };
    setCriteria(newCriteria);
  }
  const [path, setPath] = useState();
  const [timeValue, setTimeValue] = useState();
  const [count, setCount] = useState();
  const [between, setBetween] = useState(false);

  function MultiplePathHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        path_value: e.target.value
      },  
    };
    setCriteria(newCriteria);
    setPath(e.target.value);
  }

  function MultiplePathOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        path_operator: e.value
      },  
    };
    setCriteria(newCriteria);
  }

  function MultipleTimeHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: 
        {...newCriteria[currentItemIndex].multiple_filter,
          time_value: e.target.value
        },
    };
    setCriteria(newCriteria);
    setTimeValue(e.target.value);
  }

  function MultipleTimeOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        time_operator: e.value
      },  
    };
    setCriteria(newCriteria);
    //setPath(e.target.value);
  }

  function MultipleCountHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        count_value: e.target.value
      },
    };
    setCriteria(newCriteria);
    setCount(e.target.value);
  }

  function MultipleCountOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        count_operator: e.value
      },  
    };
    setCriteria(newCriteria);
  }

  function ValueFromHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        value_from: e.target.value
      },  
    };
    setCriteria(newCriteria);
  }
  
  function ValueToHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        value_to: e.target.value
      },  
    };
    setCriteria(newCriteria);
    setBetween(true);
  }

  function formSubmit(e) {
    e.preventDefault();
    let hasError = false;

    switch (true) {
    case name.length <= 0:
      setError('Please enter name');
      hasError = true;
      return;
   
    case filterType.length <= 0:
      setError('Please choose filter type');
      hasError = true;
      return;
    case !between && filterValue.length <= 0 :
      setError('Please enter value');
      hasError = true;
      return;
    case between && criteria[0].multiple_filter.value_from == '' && criteria[0].multiple_filter.value_to == '':
      setError('Please choose values');
      hasError = true;
      return;
    default: 
      setError(false);
    }
    if (hasError) return;

    if(!edit && validateForm()) {
      const formData = {
        name: name,
        page_path: url,
        google_analytics_view_id_id: selectedId.value,
        lead_filters_attributes: criteria,
        redirects: redirectFields

      };
      
      handleSubmit(formData);

    }
    if (edit && validateForm()) {
      const formData = {
        name: name,
        page_path: url,
        google_analytics_view_id_id: selectedId.value,
        lead_filters_attributes: criteria
      };
      dispatch(putPersonalization({id: editItem.id, body:formData}));   
      setEditted(true);
      handleShowFormOff();
    }
  }

  function validateForm() {
    let isValid = true;
    const pattern = /(http:\/\/|https:\/\/|http:|https:|www\.)/g;

    const criteriaValidationErrors = criteria.map((item) => {
      const errors = {};
      if (item.key == "page_path" && pattern.test(item.value)) {
        setHigh('high');
        errors.value = 'Please include page path without domain name, like "/contact_us", but not "https://abc.com/contact_us"';
        isValid = false;
      }

      if (url.trim() === '') {
        errors.key = 'Please enter URL of the page';
        isValid = false;
      }

      if (url.substring(0, 4) !== 'http') {
        errors.key = 'Please enter correct URL start from "http//www"';
        isValid = false;
      } 

      if (item.key.trim() === '') {
        errors.key = 'Please choose a filter type';
        isValid = false;
      }

      if (item.operator.trim() === '') {
        errors.operator = 'Please choose an operator';
        isValid = false;
      }
    
      return errors;
    });

    setCriteriaErrors(criteriaValidationErrors);

    return isValid;
  }

  function addFilterCriteria(e) {
    e.preventDefault();
    setCriteria(criteria.concat(generateEmptyCriteria()));
  }

  function addRedirectFields(e) {
    e.preventDefault();
    setRedirectFields(redirectFields.concat(generateEmptyRedirectFields()));
  }

  function FieldFromHandler(e, item) {
    const newRedirectFields = [...redirectFields];
    const currentItemIndex = newRedirectFields.indexOf(item);
    newRedirectFields[currentItemIndex] = {
      ...newRedirectFields[currentItemIndex],
      from: e.target.value
    };
    setRedirectFields(newRedirectFields);
  }

  function FieldToHandler(e, item) {
    const newRedirectFields = [...redirectFields];
    const currentItemIndex = newRedirectFields.indexOf(item);
    newRedirectFields[currentItemIndex] = {
      ...newRedirectFields[currentItemIndex],
      to: e.target.value
    };
    setRedirectFields(newRedirectFields);
  }

  const handleDelete = (item) => () => {
    const updatedCriteria = criteria.map(e =>
      e.id === item.id ? { ...e, _destroy: 'true' } : e
    );
    setCriteria(edit ? updatedCriteria : criteria.filter(e => e._id !== item._id));
  };

  const handleDeleteRedirect = (item) => () => {
    setRedirectFields(redirectFields.filter(e => e._id !== item._id));
  };

  return (
    <Form onSubmit={formSubmit}>
      <Label>{personalizationT('typeName')}</Label> 
      <TextField value={name} onChange={handleName} placeholder='Ex: Personalization #1' />
      <Label>{personalizationT('chooseWebsite')}</Label> 
      <Select
        defaultValue={edit ? filterNameOptions.find((el) => el.value === editItem.ga_view.id) : selectedId}
        options={filterNameOptions}
        onChange={handleSelectChange}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null
        }}
      />
      <Label>{personalizationT('enterUrl')}</Label>
      <TextField type='url' placeholder="https://example.com" value={url} onChange={handleUrl} />
      <Label className='define'>{personalizationT('criteria')}</Label>

      {criteria?.map((item, index) => (
        <CascadWrapper key={index} className="cascad personalize" id={item._destroy == 'true' ? 'destroy' : ''}>
          <Wrapper className="operator">
            {
              <StyledSelectSmall
                placeholder='--'
                options={operatorOptions}
                //value={filterOperator}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
                defaultValue={ edit ? operatorOptions.find((el) => el.value === item.join_operator) : 'AND' }
                onChange={(e) => filterOperatorHandler(e, item)}
                styles={customStyles}
              />
            }
          </Wrapper>
          <Cascad
            handlerType={filterTypeHandler}
            handlerEqual={filterEqualHandler}
            handlerValue={filterValueHandler}
            filterValue={filterValue}
            styles={customStyles}
            index={index}
            item={item}
            edit={edit}
            setIsSelectValue={setIsSelectValue}
            naicsHandler={filterValueNaicsHandler}
            gic={gic}
            naics={naics}
            PathHandler={MultiplePathHandler}
            pathOperator={MultiplePathOperatorHandler}
            timeOperator={MultipleTimeOperatorHandler}
            countOperator={MultipleCountOperatorHandler}
            path={path}
            TimeHandler={MultipleTimeHandler}
            timeValue={timeValue}
            CountHandler={MultipleCountHandler}
            count={count}
            ValueFromHandler={ValueFromHandler}
            ValueToHandler={ValueToHandler}
          />
          <Button className="delete" onClick={handleDelete(item)}>
            <Delete />
          </Button>
        </CascadWrapper>
      ))}
      <FlexWrapperStart>
        <AddButton onClick={addFilterCriteria}>
          <Plus />
          <TextAccentThin>{rootT('addCriteria')}</TextAccentThin>
        </AddButton>
      </FlexWrapperStart>
      <RedirectBox>
        <Flex>
          <CheckBox
            onChange={handleRedirect}
            name="redirect"
            type="checkbox"
            checked={redirect}  
          />
          <span></span>
          <TextBlackThin className='description'> {personalizationT('redirectText')}</TextBlackThin>
        </Flex>
        {redirect && (
          <>
            {redirectFields?.map((item, index) => (
              
              <RedirectCascad key={index}>
                <FieldsWrapper>
                  <Fields>
                    <TextBlackThin>{personalizationT('from')}</TextBlackThin>
                    <TextField 
                      type='url'
                      value={edit ? item.from : item.fieldFrom} 
                      onChange={(e) => FieldFromHandler(e, item)} 
                      placeholder='Enter URL' />
                  </Fields>
                  <Fields>
                    <TextBlackThin>{personalizationT('to')}</TextBlackThin>
                    <TextField 
                      type='url'
                      value={edit ? item.to : item.fieldTo} 
                      onChange={(e) => FieldToHandler(e, item)} 
                      placeholder='Enter URL' />
                  </Fields>
                  <Button className="delete" onClick={handleDeleteRedirect(item)}>
                    <Delete />
                  </Button>
                </FieldsWrapper>
                
              </RedirectCascad>
            ))}
        
            <FlexWrapperStart>
              <InsertButton onClick={addRedirectFields}>
                <Plus />
                <TextAccentThin>{personalizationT('insert')}</TextAccentThin>
              </InsertButton>
            </FlexWrapperStart>
          </>
        )
        }    
      </RedirectBox>
     
      <TextAccentExtraSmall> {error}</TextAccentExtraSmall>
      {criteriaErrors?.length > 0 && criteriaErrors?.map((errors, index) => (
        <div key={index} className={high}>
          {errors.key && <TextAccentExtraSmall className={errors ? 'relative' : 'relative'}>
            {errors.key}</TextAccentExtraSmall>}
          {errors.operator && (
            <TextAccentExtraSmall className={errors ? 'relative' : 'relative'}>{errors.operator}</TextAccentExtraSmall>
          )}
          {errors.value && <TextAccentExtraSmall className={errors ? 'relative' : 'relative'}>
            {errors.value}</TextAccentExtraSmall>}
        </div>
      ))}
      <Buttons>
        <SecondaryBtnHigh type="reset" onClick={handleShowFormOff}>
          {personalizationT('close')}
        </SecondaryBtnHigh>
        <FilledButton type="submit">{rootT('save')}</FilledButton>
      </Buttons>
      {statusNew && notification && (
        <Notification>
          <Close onClick={handleOffNotification}><CloseIcon /></Close>
          <Checked />
          <TextBlackThin>{personalizationT("notification")}
            <a href={url+'?vqpcid='+id} target='_blank' rel="noreferrer">{url+'?vqpcid='+id}</a>
            {personalizationT("notificationStart")}
          </TextBlackThin>
        </Notification>
      )}
    </Form>
  );
}

NewForm.propTypes = {
  handleShowFormOff: func,
  edit: bool, 
  editItem: object,
  setShowCampaign: func,
  personalization: any,
  gic: array,
  naics: array,
  campaign: any,
  setEditted: func,
  statusNew: string, 
  notification: bool, 
  handleOffNotification: func,
  id: string
};
