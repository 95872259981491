import { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isStatusSucceed, makeAsOptions } from 'base/utils';
import { fetchAllLeads, fetchLeadsPerPage } from 'store/slices/leads';
import styled from 'styled-components';
import {
  AddQueue,
  Sorting,
  TableLeads,
  Countries,
  Calendar,
  Charts,
  Companies,
  Filter,
} from 'base/components/Leads';
import { components } from 'react-select';
import {
  Main,
  OutlinedButton,
  FlexWrapper,
  StyledSelect,
  Overflow,
  SearchForm,
  Loading,
  TextGreyThin,
  TextBlackSmallThin,
  TextAccentSmall,
  Flex,
  Modal,
  TextBlack,
  ShadowButton,
  Result,
} from 'base/styled';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { ReactComponent as Refresh } from 'assets/images/refresh.svg';
import DetailInfo from 'base/components/Leads/Details/DetailInfo';
import Onboarding from 'base/components/Leads/Onboarding/Onboarding';
import NoData from 'base/components/Leads/NoData';
import Responsive from 'context/responsive';
import network from 'base/network';
import NoLeads from 'base/components/Leads/NoLeads';
import { any } from 'prop-types';
import HitLimit from './HitLimit';
import WasConnected from 'base/components/Leads/WasConnected';
import Sunsetted from 'base/components/Leads/Sunsetted';
import InvitationCard from 'base/components/Leads/InvitationCard';
import { useCookies } from 'react-cookie';
import { fetchDashboardQueues, fetchDashboardQueuesDropdown } from 'store/slices/dashboardQueues';
import Survey from 'base/components/Leads/Survey';

const ButtonRow = styled.div`
  padding: 20px 0;
  text-align: center;
  .d-none {
    display: none;
  }
`;

const FlexWrapperGrafic = styled(FlexWrapper)`
  padding-top: 29px;
  .center {
    text-align: center;
    margin: 10px auto 20px;
  }
  @media (max-width:1160px) {
    flex-wrap: wrap;
  }
  @media (max-width:1112px) {
    .sc-htpNat {
      margin: 8px 0
    }
    .dCruYn {
      width: 100%;
    }
  }
  @media (max-width:768px) {
    flex-direction: column;
    .sc-htpNat, .bNGCaI {
      width: 100%;
    }
  }
`;

const FlexWrapperJustify = styled(FlexWrapper)`
  &.impers {

    .with-modal {
      .filtered {
        top: 40px!important;
      }
    }
  
  }
  &&& {
    justify-content: space-between;
    padding: 20px 20px 0;
    .with-modal-sm {
      position: relative;
    }
    .sort-btn {
      margin-left: 20px;
    }
    .with-modal {
      padding-top: 3px;
      a {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 30px;
        svg {
          margin-right: 5px;
        }
        &:last-child {
          position: static;
        }
      }
      .sorted,
      .filtered {
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.13);
        border-radius: 6px;
        background: #fff;
        z-index: 1;
        
      }
      .sorted {
        position: absolute;
        top: 36px;
        right: 3px;
        width: 135px;
        z-index: 33;
      }
      .filtered {
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        width: 60%;
        overflow-y: auto;
        z-index: 90;
        .sc-hSdWYo {
          // padding: 30px 30px 40px;
        }
      }
      .d-none {
        display: none;
      }
    }
    @media (max-width: 768px) {
      padding: 5px 0;
      align-items: center;
      .with-modal {
        &.sort-btn {
          margin-left: 0; 
          a {
            margin-right: 0;
            padding-bottom: 10px;
            white-space: nowrap;
          }
        }
        .filtered {
          border-radius: 0px 0px 16px 16px;
          width: 100%;
          height: 85%;
        }
        .sorted {
          right: 0;
          width: 180px;
        }
      }
    }
   
  }
`;

const FlexWrapperJustifyBetween = styled(FlexWrapperJustify)`
  h1 {
    margin: 4px 30px 0 0;
  }
`;

const MainLead = styled.div`
  background: inherit;
  width: ${(props) => (props.details ? '70%' : '100%')};
  @media (max-width: 1160px) and (min-width: 991px ) {
    padding-left: 3%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Over = styled(Overflow)`
  left: -140px;
  height: 150vh;
  top: -95px;
  z-index: 9;
  height: 150vh;
 
  @media (max-width: 768px) {
    left: -2px;
  }
`;

const Count = styled.div`
  display: flex;
  flex-direction: column;
  height: 41px;
  width: 115px;
  padding-top: 9px;
  p {
    margin: 1px 0;
    white-space: nowrap;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    justify-content: start;
    text-align: right;
  }
`;

const LoadCount = styled.div`
  height: 41px;
  width: 115px;
  padding-top: 7px;
  div {
    margin-top: 0
  }
`;

const SelectAll = styled.div`
  cursor: pointer;
  p {
    text-decoration: underline;
    font-weight: 400;
  }
`;

const tableLoading = {
  height: '100vh'
};

const Relative = styled.div`
  position: relative;
`;

const FlexMobile = styled(FlexWrapperJustify)`
  background: #fff;
  border-spacing: 0;
`;

const White = styled.div`
  background: white;
  border-radius: 10px;
  margin-top: 20px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

export default function Leads() {
  const { t: homeT } = useTranslation('home');
  const { t: rootT } = useTranslation();
  const { t: onboardingT } = useTranslation('onboarding');
  const {
    page,
    filters,
    count,
    leads,
    status: leadsStatus,
    detailsStatus: detailsStatus
  } = useSelector((state) => state.leadsReducer);
  const gaViewId = useSelector(
    (state) => state.gaViewIdReducer.selectedId?.value
  );
  const gaViews = useSelector((state) => state.gaViewIdReducer);

  const dispatch = useDispatch();
  const { queues, queuesDropdown } = useSelector((state) => state.dashboardQueuesReducer);
  
  const options = makeAsOptions(queuesDropdown, 'id', 'name');
  const [leadsWithCheck, setLeadsWithCheck] = useState([]);
  const [sortType, setSortType] = useState(null);
  const [queueId, setQueueId] = useState('');
  const [startDay, setStartDay] = useState(new Date(Date.now()- 1000 * 60 * 60 * 24 * 7).toISOString().slice(0, 10));
  const [endDay, setEndDay] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [success, setSuccess] = useState();
  const [queue, setQueue] = useState(null);
  const [value, setValue] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [leadToShow, setLeadsToShow] = useState(null);
  const [onboard, setOnboard] = useState(false);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  const [leadsLength, setLeadsLength] = useState(true);
  const [leadsMarked, setLeadsMarked] = useState([]);
  const [leadsHide, setLeadsHide] = useState([]);
  const [search, setSearch] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [selectAll, setSelectedAll] = useState(false);
  const [listChecked, setListChecked] = useState([]);
  const [loader, setLoader] = useState(false);
  const [connected, setConnected] = useState(true);
  const [sunsetted, setSunsetted] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedIdShort, setSelectedIdShort] = useState();
  const [addFilter, setAddFilter] = useState(false);
  const [warning, setWarning] = useState(false);
  const [one, setOne] = useState(false);
  const [clickedTr, setClickedTr] = useState(null);
  const [wasConnected, setWasConnected] = useState(false);
  const {userFilters, fulfilled} = useSelector((state) => state.userFReducer);
  const {accFilters} = useSelector((state) => state.accFReducer);
  const [refresh, setRefresh] = useState(false);
  const [invitation, setInvitation] = useState(false);
  const [successInvite, setSuccessInvite] = useState(false);
  const [survey, setSurvey] = useState(false);
  const { user } = useSelector((state) => state.userReducer);
  const ctx = useContext(Responsive);
 
  useEffect(() => {
    if (window.location.href.indexOf("?onboarding=true") > -1) {
      setOnboard(true);
    }
    /* eslint-disable */
  }, [window.location.href]);
    /* eslint-enable */

  useEffect(() => {
    if (gaViewId) {
      const foundView = gaViews?.ids?.find((el) => el.id === gaViewId);
      setSelectedId(foundView?.website_id);
      setSelectedIdShort(foundView?.id);

      setConnected(foundView?.connected || foundView?.was_connected);
      setWasConnected(!foundView?.connected && foundView?.was_connected);
      setSunsetted(foundView?.sunsetted);
      ctx.setSunset(!!foundView?.sunsetted);
    } else {
      setSelectedId(null);
    }

    setValue();
    setCheckAll(false);
  }, [gaViewId, gaViews, ctx]);

  // prev
  // useEffect(() => {
    
  //   if (gaViewId) {
  //     setSelectedId(gaViews?.ids?.find((el) => el.id === gaViewId)?.website_id);
  //     setSelectedIdShort(gaViews?.ids?.find((el) => el.id === gaViewId)?.id);
  //     if(selectedId) {
  //       dispatch(
  //         fetchAllLeads({
  //           gaViewId,
  //           leads_order: sortType,
  //           queue_id: queueId,
  //           startDate: startDay,
  //           endDate: endDay,
  //           q: value,
  //           per_page: 10,
  //           archived: queueId == -2, 
  //           ...filters,
  //         })
  //       );
  //     }
  //     setConnected(gaViews.ids.find((el) => el.id === gaViewId).connected ||
  //     gaViews.ids.find((el) => el.id === gaViewId).was_connected );
  //     setWasConnected(!gaViews.ids.find((el) => el.id === gaViewId).connected && 
  //     gaViews.ids.find((el) => el.id === gaViewId).was_connected);
  //     setSunsetted(gaViews.ids.find((el) => el.id === gaViewId).sunsetted);
  //     if(gaViews.ids.find((el) => el.id === gaViewId).sunsetted) {
  //       ctx.setSunset(true);
  //     } else {
  //       ctx.setSunset(false);
  //     }
  //   }
  //   setValue();
  //   setCheckAll(false);
  //   /* eslint-disable */
  // }, [
  //   dispatch,
  //   gaViewId,
  //   filters,
  //   sortType,
  //   queueId,
  //   startDay,
  //   endDay,
  //   search,
  //   addFilter,
  //   userFilters,
  //   selectedId, refresh
  // ]);
  //  /* eslint-enable */
  
  // new
  const prevUserFilters = useRef([]);
  useEffect(() => {
    if (userFilters?.length > 0 && prevUserFilters?.current?.length === 0) {
      prevUserFilters.current = userFilters;
    }
  }, [userFilters]);

  useEffect(() => {
    if (fulfilled === false) return;
    if (selectedId && gaViewId) {
      dispatch(
        fetchAllLeads({
          gaViewId,
          leads_order: sortType,
          queue_id: queueId,
          startDate: startDay,
          endDate: endDay,
          q: value,
          per_page: 10,
          archived: queueId == -2,
          ...filters,
        })
      );
      prevUserFilters.current = userFilters;
    }
    /* eslint-disable */
  }, [
    dispatch,
    selectedId,
    gaViewId,
    filters,
    sortType,
    queueId,
    startDay,
    endDay,
    value,
    search,
    addFilter,
    JSON.stringify(userFilters),
    refresh,
    fulfilled
  ]);
  /* eslint-enable */

  const { details } = useSelector(
    (state) => state.accountReducer
  ); 

  const [cookies, setCookie] = useCookies(["invite_window_shown"]);
 
  useEffect(() => {
    const diff = Date.parse(details?.trial_end_date) - Date.now();
    if (diff <= 12 * 24 * 60 * 60 * 1000 && cookies.invite_window_shown != 'true') { // 12 days
      setInvitation(true);
    }
  }, [details, cookies]);

  const getIndGic = async () => {
    let res = await network
      .get('/api/industries/gic', { params: {} })
      .then(({ data }) => data)
      .catch((error) => console.log(error));

    setGic(res?.results?.map((d) => {
      return {
        value: d[0],
        label: d[1]
      };
    }));
  };

  const getInd = () => {
    network
      .get('/api/industries/naics')
      .then(({ data }) => {      
        setNaics(data.results?.map(d => {
          return {
            label: d.value,
            value: d.code,
            options: d.subindustries.map((e) => {
              return {
                label: Object.values(e).toString(),
                value: Object.keys(e).toString(),
              };
            }),
          };
        }
        ));})
      .catch((error) => console.log(error));
    
  };

  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    if (pageNumber >= 1 && selectedId) {
      dispatch(fetchDashboardQueues( {id: selectedIdShort, per_page: 100, page: pageNumber}));
      dispatch(fetchDashboardQueuesDropdown( {id: selectedIdShort, per_page: 100, page: 1}));
    }
    /* eslint-disable */
  }, [pageNumber, selectedId, dispatch]);
   /* eslint-enable */

  const [gic, setGic] = useState([]);
  const [naics, setNaics] = useState([]);
  const [statsText, setStatText] = useState(false);
  const [idName, setIdName] = useState('');

  useEffect(() => {
    getInd();
    getIndGic();
  }, []);

  useEffect(() => {
    setCheckAll(false);
    setSelectedAll(false);
  
    if (leads) {
      const newLeads = leads.reduce((acc, current) => {
        const existingItem = acc.find(item => item.id === current.id);
  
        if (!existingItem) {
          acc.push(current);
        } else {
          const currentDate = new Date(current.last_visited_at);
          const existingDate = new Date(existingItem.last_visited_at);
  
          if (currentDate > existingDate) {
            acc = acc.map(item => 
              item.id === existingItem.id 
                ? { ...existingItem, last_visited_at: current.last_visited_at }
                : item
            );
          }
        }
        return acc;
      }, []);
  
      setLeadsWithCheck(
        newLeads.map(d => ({
          select: leadsMarked?.includes(d.id) ? true : false,
          id: d?.id,
          name: d?.name,
          logo: d?.logo,
          location: d?.location,
          visit: d?.last_visited_at,
          count: d?.page_visit_count,
          time: d?.total_time_spent,
          source: d?.source_for_dashboard,
        }))
      );
    } else {
      setLeadsLength(false);
    }
    /* eslint-disable */
  }, [leads, page]);
  /* eslint-enable */

  const handleShowDetails = (idx, index) => () => {
    setClickedTr(index);
    const leadToShow = leads.find((el) => el.id === idx);
    setLeadsToShow(leadToShow);
    if (showDetails) {
      setLeadsToShow(leadToShow);
      return;
    } else {
      setShowDetails(true);
      ctx.setDetails(true);
      ctx.setNavbar(false);
    }
  };

  function handleShowDetailsOff() {
    setShowDetails(false);
    ctx.setDetails(false);
    setClickedTr(null);
  }

  function handleOnboardOff() {
    setOnboard(false);
  }

  function showResult(e) {
    e.preventDefault();
    setSearch(!search); 
  }

  let handleSearchResults = (ev) => setValue(ev.target.value);

  function loadMore() {
    dispatch(fetchLeadsPerPage({ 
      gaViewId, 
      leads_order: sortType,
      queue_id: queueId,
      startDate: startDay,
      endDate: endDay,
      per_page: 10,
      q: value, 
      page: page + 1 }));
  }
  
  async function handleSelectChange(payload) {
    await setQueue(payload);
    setQueueId(payload.value);
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: '12px',
      background: 'transparent',
      color: state.selectProps.menuColor,
      borderBottom: state.isSelected ? '1px solid #EDEDED' : '0',
      padding: state.isSelected ? '10px 5px 10px 10px' : '10px 5px 10px 35px',
      margin: 0,
    }),
    control: () => ({
      borderRadius: '18px',
      border: 'transparent',
      background: '#fff',
      boxShadow: '0px 1px 5px rgba(87, 85, 85, 0.24)',
      display: 'flex'
    }),
    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
      padding: 0,
      overflowX: 'hidden',
      display: 'inline-block',
      '@media only screen and (max-width: 1200px)': {
        padding: 0,
      },
    }),
  };

  const handleCheckedAll = (e) => {
    setCheckAll(!checkAll);
    let checked = e?.target?.checked;
    leadsWithCheck.map(function (d) {
      d.select = checked;
      return d;
    });
    setLeadsMarked(leadsWithCheck.map((e) => e.id));
    if (checkAll) {
      setLeadsMarked([]);
    }
  };

  const fetchAll = () => {  
    setLoader(true); 
    setCheckAll(true);
    setSelectedAll(true);   
    setListChecked(leadsWithCheck.map(function (d) {
      d.select = true;
      return d;
    }));  
    console.log(listChecked);
    setLoader(false);
  };

  const handleSelectAll = () => {
    fetchAll();
  };
 
  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      {props.isSelected && <CheckIcon className='check' />}
      {props.data.label}
    </Option>
  );

  useEffect(() => {
    if(ctx.hitLimit) {
      setWarning(true);
    }
  }, [ctx]);

  function setId () {
    setIdName('hidden');
    setLeadsHide((prevList => [...prevList, ...leadsMarked]));
    setShowDetails(false);
  }

  useEffect(() =>{
    if(idName=='hidden') {
      setIdName();
    } 
    /* eslint-disable */
  }, [queueId]);
    /* eslint-enable */

  useEffect(() => { 
    if(successInvite) {
      handleInvitationOff();  
    }
    
    const timerIn = setTimeout(() => {
      if(successInvite) {
        setSuccessInvite(false);
      }
    }, 2800);
    return () => clearTimeout(timerIn);
    /* eslint-disable */
  }, [successInvite]);
   /* eslint-enable */

  useEffect(() => {
    if (user?.email && cookies.survey_shown != 'true') {
      handleSurvey();
    }
    /* eslint-disable */
  }, [user, cookies]);
    /* eslint-enable */

  const [surveyTypes, setSurveyTypes] = useState([]);
  const [surveyId, setSurveyId] = useState([]);

  const handleSurvey = async () => {
    await network
      .get('/api/surveys', { params: {email: user.email} })
      .then(({ data }) => {
        if(data.results?.length && data?.results[0].id) {
          setSurvey(true);
          setSurveyTypes(data.results[0].questions[0].options);
          setSurveyId(data.results[0].id);
        }
      })
      .catch((error) => console.log(error));
  };

  function handleSurveyOff() {
    setSurvey(false);
    setCookie("survey_shown", true, { path: "/", maxAge: 60 * 60 });
  }

  function handleInvitationOff() {
    setInvitation(false);
    setCookie("invite_window_shown", true, { path: "/", maxAge: 12 * 24 * 60 * 60 });
  }

  return (
    <Main className={ctx.details ? 'lefted' : open ? 'open' : ''}>
      {onboard && <Onboarding handleOnboardOff={handleOnboardOff} />}
      {invitation && <InvitationCard handleInvitationOff={handleInvitationOff} setSuccess={setSuccessInvite} />}
      {survey && <Survey handleSurveyOff={handleSurveyOff} email={user?.email} types={surveyTypes} id={surveyId} />}
      {successInvite && <Result>{onboardingT('sentInvite')}</Result>}
      {!connected && !sunsetted && 
      <NoData 
        id={selectedId} 
        details={details}
      />}
      {sunsetted && <Sunsetted />}
      {connected && leadsLength && (
        <MainLead details={showDetails} className={ctx.isTrial ? 'topbar' : ''}>
          {open && (
            <Over>
      
            </Over>
          )}
          {ctx.role == 'admin' ? (
            gaViews?.ids.find((el) => el.id === gaViewId)?.website_id && (
              <>
                {ctx.isMobile ? (
                  <FlexWrapperGrafic>
                    <Charts
                      details={showDetails}
                      id={gaViews?.ids.find((el) => el.id === gaViewId).website_id}
                    />
                    { statsText &&  (<>
                      <Countries
                        id={gaViews?.ids.find((el) => el.id === gaViewId).website_id}
                      />
                      <Companies
                        id={gaViews?.ids.find((el) => el.id === gaViewId).website_id}
                      /></>)}
                    <TextBlack className='center' onClick={() => setStatText(!statsText)}>
                      {statsText ? 'View less stats' : 'View more stats'}</TextBlack>
                  </FlexWrapperGrafic>
                ) : (
                  <FlexWrapperGrafic>
                    <Companies
                      id={gaViews?.ids.find((el) => el.id === gaViewId).website_id}
                    />
                    <Charts
                      details={showDetails}
                      id={gaViews?.ids.find((el) => el.id === gaViewId).website_id}
                    />
                    {!ctx.details && (
                      <Countries
                        id={gaViews?.ids.find((el) => el.id === gaViewId).website_id}
                      />
                    )}
                  </FlexWrapperGrafic>
                )
                }
              </>
            )
          ) : null }
          <White>
            {!ctx.isMobile && (
              <FlexWrapperJustifyBetween>
                <Flex>
                  <h1>Leads</h1> 
                  <StyledSelect
                    options={options}
                    placeholder={homeT('allLeads')}
                    onChange={handleSelectChange}
                    value={queue}
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: () => null,
                      Option: IconOption,
                    }}
                  />
                  <Calendar
                    setStartDay={setStartDay}
                    setEndDay={setEndDay}
                    setChangeDate={setChangeDate}
                  />
                </Flex>
                <SearchForm onSubmit={showResult}>
                  <SearchIcon onClick={showResult} />

                  <input
                    type="search"
                    onChange={e => setValue(e.target.value)}
                    value={value || ''}
                  />
                </SearchForm>
              
              </FlexWrapperJustifyBetween>
            )}
            {ctx.isMobile && (
              <>
                <FlexWrapperJustifyBetween>
                  <h1>Leads</h1>              
                  <SearchForm onSubmit={showResult}>
                    <SearchIcon onClick={showResult} />
                    <input
                      type="search"
                      placeholder=""
                      onChange={handleSearchResults}
                      value={value}
                    />
                  </SearchForm>
                
                </FlexWrapperJustifyBetween>
                <FlexMobile>
                  <StyledSelect
                    options={options}
                    placeholder={homeT('allLeads')}
                    onChange={handleSelectChange}
                    value={queue}
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: () => null,
                      Option: IconOption,
                    }}
                  />
                  <Sorting setSortType={setSortType} />
                </FlexMobile>
                <FlexMobile>
                  <Calendar
                    setStartDay={setStartDay}
                    setEndDay={setEndDay}
                    setChangeDate={setChangeDate}
                  />
                </FlexMobile>
                <Count>
                  {ctx.isMobile && <ShadowButton className='reload' onClick={() => setRefresh(!refresh)}>
                    {homeT('refresh')}
                    <Refresh />
                  </ShadowButton>}
                  {leads &&
                    (leadsMarked?.length > 0 && !one ? (
                      <>
                        <TextBlackSmallThin>
                          {leadsMarked.length}
                          {homeT('leadsSelected')}
                        </TextBlackSmallThin>
                        <SelectAll onClick={handleSelectAll}>
                          <TextAccentSmall>
                            {homeT('selectAll')} {count}
                          </TextAccentSmall>
                        </SelectAll>
                      </>
                    ) : (
                      <TextBlackSmallThin>
                        {' '}
                        {count}
                        {homeT('leads')}
                      </TextBlackSmallThin>
                    ))}
                </Count>
              </>
            )}
            {leadsLength && leads && (
              <Relative>
                <FlexWrapperJustify
                  className={
                    ctx.isImpersonate || ctx.isTrial || ctx.isLiteFree
                      ? 'impers'
                      : ''
                  }
                >
                  {!ctx.isMobile && (
                    <Flex>
                      {loader ? (
                        <LoadCount>
                          <Loading />
                        </LoadCount>
                      ) : (
                        <Count>
                          {leads &&
                            (leadsMarked?.length > 0 && !one ? (
                              <>
                                <TextBlackSmallThin>
                                  {selectAll ? count : leadsMarked.length }
                                  {one}
                                  {homeT('leadsSelected')}
                                </TextBlackSmallThin>
                                <SelectAll onClick={handleSelectAll}>
                                  {!ctx.isLiteFree && (
                                    <TextAccentSmall>
                                      {homeT('selectAll') + count}
                                    </TextAccentSmall>
                                  )}
                                </SelectAll>
                              </>
                            ) : (
                              <TextBlackSmallThin>
                                {' '}
                                {count}
                                {homeT('leads')}
                              </TextBlackSmallThin>
                            ))}
                        </Count>
                      )}
                      <Sorting setSortType={setSortType} />
                    </Flex>
                  )}
                  {!ctx.isMobile && <ShadowButton onClick={() => setRefresh(!refresh)}>
                    {homeT('refresh')}
                    <Refresh />
                  </ShadowButton>}
                  <FlexWrapper>
                    <AddQueue
                      setOpen={setOpen}
                      open={open}
                      queues={queues}
                      gic={gic}
                      naics={naics}
                      pageNumber={pageNumber} 
                      setPageNumber={setPageNumber}
                    />
                 
                    {(details?.settings?.restrict_manager_filters != '1' &&
                      ctx.impersonate?.role !== 'user') ||
                    (details?.settings?.restrict_manager_filters == '1' &&
                      ctx.impersonate?.role === 'admin') ? (
                        <Filter
                          setOpen={setOpen}
                          open={open}
                          filter={filter}
                          setFilter={setFilter}
                          setAddFilter={setAddFilter}
                          addFilter={addFilter}
                          gic={gic}
                          naics={naics}
                          contactDisabled={
                            details?.settings?.disable_dashboard_contacts
                          }
                          indicator={accFilters?.length > 0 || userFilters?.length > 0}
                        />
                      ) : null}
                  </FlexWrapper>
                </FlexWrapperJustify>
               
                {isStatusSucceed(leadsStatus) ? (
                  <TableLeads
                    leadsWithCheck={leadsWithCheck}
                    gaViewId={gaViewId}
                    showDetails={showDetails}
                    success={success}
                    setSuccess={setSuccess}
                    leadsMarked={leadsMarked}
                    setLeadsMarked={setLeadsMarked}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                    handleCheckedAll={handleCheckedAll}
                    setListChecked={setListChecked}
                    clickedTr={clickedTr}
                    leadsHide={leadsHide}
                    setLeadsHide={setLeadsHide}
                    setId={setId}
                    idName={idName}
                    setIdName={setIdName}
                    /* eslint-disable */
                    handleShowDetails={handleShowDetails}
                    maxDate={startDay}
                    minDate={endDay}
                    selectAll={selectAll}
                    one={one}
                    setOne={setOne}
                    setSelectAll={setSelectedAll}
                    setAddFilter={setAddFilter}
                    addFilter={addFilter}
                    queueId={queueId}
                    archived={queueId == -2}
                    /* eslint-enable */
                  />
                ) : (
                  <Loading style={tableLoading} />
                )}
                {showDetails && (
                  <DetailInfo
                    leadToShow={leadToShow}
                    leadName={leadToShow.name}
                    leadLogo={leadToShow.logo}
                    leadLocation={leadToShow.location}
                    leadTime={leadToShow.total_time_spent}
                    leadCount={leadToShow.page_visit_count}
                    date={leadToShow.last_visit_for_dashboard}
                    leadSource={leadToShow.source_for_dashboard}
                    handleShowDetailsOff={handleShowDetailsOff}
                    gaViewId={gaViewId}
                    leadsStatus={leadsStatus}
                    detailsStatus={detailsStatus}
                    setFilter={setFilter}
                    setAddFilter={setAddFilter}
                    leadsHide={leadsHide}
                    setLeadsHide={setLeadsHide}
                    setId={setId}
                    idName={idName}
                    setIdName={setIdName}
                    archived={queueId == -2}
                    contactDisabled={
                      details?.settings?.disable_dashboard_contacts
                    }
                  />
                )}
                <ButtonRow>
                  {leads?.length > 0 ? (
                    <OutlinedButton
                      onClick={loadMore}
                      className={
                        !isStatusSucceed(leadsStatus) || leads?.length == count
                          ? 'd-none'
                          : ''
                      }
                      disabled={!isStatusSucceed(leadsStatus)}
                    >
                      {rootT('loadMore')}
                    </OutlinedButton>
                  ) : changeDate || queueId != -1 ? (
                    <TextGreyThin>{homeT('noMatch')}</TextGreyThin>
                  ) : (
                    <TextGreyThin>{homeT('noLeads')}</TextGreyThin>
                  )}
                </ButtonRow>
              </Relative>
            )}
          </White>
          {/* {details?.settings?.allow_ai_search == '1' && <SmartSearch id={gaViewId} />} */}
        </MainLead>
      )}
      {connected && !leadsLength && <NoLeads />}
      {warning && (
        <Modal>
          <Overflow onClick={() => setWarning(false)}></Overflow>
          <HitLimit amount={details?.approx_leads_till_end_billing_cycle}/>
        </Modal>
      )}
      {wasConnected && <WasConnected 
        days={gaViews.ids.find((el) => el.id === gaViewId)} 
        setWasConnected={setWasConnected} />}
    </Main>
  );
}

Leads.propTypes = {
  isSelected: any,
  data: any,
  label: any
};